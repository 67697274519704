<template>
    <v-alert
        class="w-100 text-muted mt-2 mb-0"
    >
        <b>Hinweis:</b>
        <ul class="mb-0">
            <li>
                Beim Abschluss jeder Runde gibt es ein Reflexionsprotokoll mit
                systemischen Reflexionsfragen, welche u.a. einen
                "psychohygienischen" Beitrag zum Abschluss der Streetwork leisten.
                Dazu wird bei Abschluss einer Runde zufällig eine dieser Fragen gestellt und muss beantwortet werden.
            </li>
            <li>
                Die systemischen Fragen sind nur für
                <router-link :to="{name: 'Teams'}">Teams</router-link>
                relevant, für die systemische Fragen aktiviert sind.
                <br>
                <template
                    v-if="teamsWithSystemicQuestions"
                >
                    Deine Teams mit systemischen Fragen sind: {{ teamsWithSystemicQuestions }}
                </template>
                <template
                    v-else
                >
                    Du hast bisher noch keine Teams mit systemischen Fragen.
                </template>
            </li>
            <li>
                Eine Änderung der Fragestellung ändert lediglich die Fragestellung für neue Runden.
            </li>
            <li>
                Bereits bestehende Runden bleiben von Änderungen an einer Frage unberührt.
            </li>
        </ul>
    </v-alert>
</template>

<script>
'use strict';

import { useTeamStore } from '../../stores';

export default {
    name: 'SystemicQuestionHint',
    props: {},
    components: {},
    data: function () {
        return {
            teamStore: useTeamStore(),
        };
    },
    computed: {
        teamsWithSystemicQuestions() {
            return this.teams.filter(team => team.isWithSystemicQuestion).map(team => '"' + team.name + '"').join(', ');
        },
        teams() {
            return this.teamStore.getTeams;
        },
    },
    async created() {
    },
    methods: {},
};
</script>
