<template>
    <div>
        <content-collapse
            title="Liste der Systemischen Fragen"
            collapse-key="list-of-systemic-questions"
            is-visible-by-default
        >
            <systemic-question-list />
        </content-collapse>
        <content-collapse
            title="Neue Systemische Frage erstellen"
            data-test="header-systemic-question-create"
            collapse-key="systemic-question-create"
        >
            <systemic-question-create />
        </content-collapse>
    </div>
</template>

<script>
    "use strict";
    import SystemicQuestionList from './SystemicQuestions/SystemicQuestionList.vue';
    import SystemicQuestionCreate from './SystemicQuestions/SystemicQuestionCreate.vue';
    import ContentCollapse from './ContentCollapse.vue';
    import { useClientStore } from '../stores';

    export default {
        name: "Teams",
        components: {
            ContentCollapse,
            SystemicQuestionList,
            SystemicQuestionCreate,
        },
        async mounted() {
            await useClientStore().fetchClients();
        },
    }
</script>

<style scoped>

</style>
